// Override Bootstrap's variables before importing

// Colors
$purple: #473BE7;
// $primary: $purple;
// $primary: #ECF0F3;
// $primary: #E4EBF2;
$primary: #8A8683;
// $body-bg: #ECF0F3;
$body-bg: #E4EBF2;
// $body-bg: #8A8683;
// $body-color: #22262E;
$body-color: #5A4D4C;
// $body-color: #8A8683;

// Jumbotron
$jumbotron-bg: white;

// Misc
$border-radius: 0;

// Type
$font-family-sans-serif: 'Inter UI', sans-serif;
$headings-margin-bottom: 0.5em;

// Then import Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";
